import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import LoginPage from "./modules/users/login";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Loadable from "react-loadable";
import React from "react";
import { setRoute } from "../src/modules/users/user.slice";
import EmailGuestlogin from "./modules/EmailGuestlogin";
import VerifyContract from "./modules/VerifyContract";
import Freetrial from "./modules/FreeTrial";
import ContactSales from "./modules/users/ContactSales";

const Loader = Loadable({
  loader: () => import("./globals/components/loader"),
  loading: () => null,
});
const ModuleRoutes = Loadable({
  loader: () => import("./modules/index"),
  loading: () => null,
});

const SetPassword = Loadable({
  loader: () => import("./modules/users/setPassword"),
  loading: () => <Loader />,
});
const ValidateOtpModel = Loadable({
  loader: () => import("./modules/users/validateOtp"),
  loading: () => <Loader />,
});
const BasicDetails = Loadable({
  loader: () => import("./modules/users/basicDetails"),
  loading: () => <Loader />,
});
const Documentation = Loadable({
  loader: () => import("./modules/Documentation"),
  loading: () => <Loader />,
});
const CreateExpressInvoice = Loadable({
  loader: () => import("./modules/ExpressWorkflow/CreateExpressInvoicebyroute"),
  loading: () => <Loader />,
});
class Routes extends React.Component {
  componentDidMount() {
    this.props.dispatch(setRoute(this.props.location));
  }
  render() {
    const props = this.props;
    return (
      <main>
        <Switch>
          <Route
            path="/login"
            exact
            render={() => {
              if (!localStorage.getItem("customerToken")) {
                return (
                  <ProtectedRoute>
                    <LoginPage />
                  </ProtectedRoute>
                );
              } else if (
                localStorage.getItem("prepath") &&
                !["/login", "/login/", "/", "/forgot"].includes(
                  localStorage.getItem("prepath")
                ) &&
                !localStorage
                  .getItem("prepath")
                  .startsWith("/guest" || "/api" || "/forgot")
              ) {
                return (
                  <Redirect
                    to={{
                      pathname: localStorage.getItem("prepath"),
                      state: { from: props.location },
                    }}
                    push={false}
                  />
                );
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/dashboard",
                      state: { from: props.location },
                    }}
                    push={false}
                  />
                );
              }
            }}
          />
          <Route
            path="/logout"
            exact
            render={() => {
              // props.user.onLogout(false);
              return (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location },
                  }}
                />
              );
            }}
          />
          <Route
            path="/guest-user/:id"
            exact
            render={() => <ValidateOtpModel {...props} />}
          />
          <Route
            path="/basic_Details"
            exact
            render={() => <BasicDetails {...props} />}
          />
          <Route
            path="/guest/:guestid/token/:tokenid"
            exact
            render={() => <EmailGuestlogin {...props} />}
          />
          <Route
            path="/forgotPassword/:id"
            exact
            render={() => <SetPassword {...props} />}
          />
          <Route
            path="/verify"
            exact
            render={() => <VerifyContract {...props} />}
          />
          <Route
            path="/free-trial"
            exact
            render={() => <Freetrial {...props} />}
          />
          <Route
            path="/documentation"
            exact
            render={() => <Documentation {...props} />}
          />
          <Route
            path="/express_contract/:id"
            exact
            render={() => <CreateExpressInvoice {...props} />}
          />
          <Route path="/" component={ModuleRoutes} />
        </Switch>
      </main>
    );
  }
}

export default withRouter(Routes);
