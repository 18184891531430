import { createAsyncThunk } from "@reduxjs/toolkit";
import ContentServices from "../../Services/contentServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { customActions } from "../Taskmanagement/Taskmanagement.Action";


export const getContent = createAsyncThunk(
  "content/getContent",
  async (data, thunkAPI) => {
    try {
      let res = await ContentServices.getContentAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi())
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createContent = createAsyncThunk(
  "Content/createContent",
  async ({ data, contentPagination }, thunkAPI) => {
    try {
      const res = await ContentServices.createcontentAPI(data);
      thunkAPI.dispatch(getContent(contentPagination));
      thunkAPI.dispatch(customActions(res.data));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteContent = createAsyncThunk(
  "Content/deleteContent",
  async ({ data, contentPagination }, thunkAPI) => {
    try {
      const res = await ContentServices.deletecontentAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getContent(contentPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateContent = createAsyncThunk(
  "Content/updateContent",
  async ({ data, contentPagination }, thunkAPI) => {
    try {
      const res = await ContentServices.updatecontentAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getContent(contentPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getbackendvariables = createAsyncThunk(
  "content/getbackendvariables",
  async (data, thunkAPI) => {
    try {
      let res = await ContentServices.getbackendvariablesAPI();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const peviewDataGet = createAsyncThunk(
  "Content/peviewDataGet",
  async ({ data, contentPagination }, thunkAPI) => {
    try {
      const res = await ContentServices.peviewDataGetApi(data);
      responseMessageHandler(res);

      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
  

      return fileURL;
      // thunkAPI.dispatch(getContent(contentPagination));
      // return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const peviewfromcontentDataGet = createAsyncThunk(
  "Content/peviewfromcontentDataGet",
  async ({ data, contentPagination }, thunkAPI) => {
    try {
      const res = await ContentServices.peviewfromcontentDataGetApi(data);
      responseMessageHandler(res);

      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
     

      return fileURL;
   
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const contentUploadDoc = createAsyncThunk(
  "Content/contentUploadDoc",
  async (data, thunkAPI) => {
    try {
    
      const paramsObj = new FormData();
      paramsObj.append("file", data.file);
      paramsObj.append("companyId", localStorage.getItem("companyId"));
      const res = await ContentServices.contentUploadDocAPi(paramsObj);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SubmitContentforcreation = createAsyncThunk(
  "Content/SubmitContentforcreation",
  async ({ data, contentPagination }, thunkAPI) => {
    try {
      const res = await ContentServices.createcontentAPI(data);
      thunkAPI.dispatch(getContent(contentPagination));
      thunkAPI.dispatch(customActions(res.data));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contentUploadhtmltoDoc = createAsyncThunk(
  "content/contentUploadhtmltoDoc",
  async (data, thunkAPI) => {
    try {
      let res = await ContentServices.contentUploadhtmltoDocAPI(data);
      
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);