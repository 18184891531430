import { createSlice } from "@reduxjs/toolkit";
import {
  getdashboarddetails,
  getvendordashboarddetails,
  getAdmindashboarddetails,
  getTaskStatusListData,
  getContractOtherStatus,
  getPieChartDeeplinkingData,
  contractTypeVsContractCount,
  dashboardModuleWisedCount,
  contractTypeBasedCount,
  getcontractByStatusCount,
  getHistoricalContractStatusBaseCount,
  getStaffwiseCount,
  getContractCount,
  getContractsByStatusAndPeriod,
} from "./dashboard.Action";
const initialState = {
  isLoading: false,
  issuccess: false,
  userrecord: {},
  propValue: {},
  companydata: {},
  vendordata: {},
  adminData: {},
  isTaskStatusData: false,
  taskStatusData: {},
  statusFilterModal: false,
  isListLoading: false,
  selectedStatusKeys: [],
  availableStatusKeys: [{ name: "", _id: 0 }],

  otherStatusbyContractData: [],
  is_Other_statusLoading: false,

  pieStatusbyContractList: [],
  is_pieStatusbyContractLoading: false,

  contractTypeVsContractCountList: [],
  contractTypeVsContractCountLoading: false,

  chartClickModal: false,
  chartClickModalType: "",
  chartClickModalDetail: {},
  chartDataListPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },

  dashboardModuleWisedCountList: [],
  dashboardModuleWisedCountLoading: false,

  contractTypeBasedCountList: [],
  contractTypeBasedCountLoading: false,
  contractByStatusCountLoading: false,
  contractByStatusCountList: {},
  HistoricalContractStatusBaseCountList: {},
  HistoricalContractStatusBaseCountLoading: false,
  staffWiseCountList: [],
  isStaffWiseCountLoading: false,
  ContractsByStatusAndPeriodList:[]
};
const dashboadrSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setStatusFilterModal: (state, { payload }) => {
      state.statusFilterModal = payload.visible;

      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.chartDataListPagination = {
        ...state.chartDataListPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.chartDataListPagination = {
        ...state.chartDataListPagination,
        search: action.payload.search || "",
      };
    },
    handleChartClickModal: (state, { payload }) => {
      state.chartClickModal = payload.visible;
      state.chartClickModalDetail = payload.record;
      state.chartClickModalType = payload.type;
      if (!payload.visible) {
        state.chartClickModalDetail = {};
      }
    },
    selectedChartData: (state, { payload }) => {
      state.selectedStatusKeys = payload.data;
    },
    resetvendorChartdata: (state, { payload }) => {
      state.selectedStatusKeys = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getdashboarddetails.pending, (state) => {})
      .addCase(getdashboarddetails.fulfilled, (state, { payload }) => {
        state.companydata = payload.data;
      })
      .addCase(getdashboarddetails.rejected, (state, { payload }) => {})
      .addCase(getvendordashboarddetails.pending, (state) => {})
      .addCase(getvendordashboarddetails.fulfilled, (state, { payload }) => {
        state.vendordata = payload.message;
      })
      .addCase(getvendordashboarddetails.rejected, (state, { payload }) => {})
      .addCase(getAdmindashboarddetails.pending, (state) => {})
      .addCase(getAdmindashboarddetails.fulfilled, (state, { payload }) => {
        state.adminData = payload.message;
      })
      .addCase(getAdmindashboarddetails.rejected, (state, { payload }) => {})

      .addCase(getTaskStatusListData.pending, (state) => {
        state.isTaskStatusData = true;
      })
      .addCase(getTaskStatusListData.fulfilled, (state, { payload }) => {
        state.taskStatusData = payload.data;
        state.isTaskStatusData = false;
      })
      .addCase(getTaskStatusListData.rejected, (state, { payload }) => {
        state.isTaskStatusData = false;
      })
      .addCase(getContractOtherStatus.pending, (state) => {
        state.is_Other_statusLoading = true;
      })
      .addCase(getContractOtherStatus.fulfilled, (state, { payload }) => {
        state.otherStatusbyContractData = payload.data;
        state.is_Other_statusLoading = false;
      })
      .addCase(getContractOtherStatus.rejected, (state, { payload }) => {
        state.is_Other_statusLoading = false;
      })
      .addCase(getPieChartDeeplinkingData.pending, (state) => {
        state.is_pieStatusbyContractLoading = true;
      })
      .addCase(getPieChartDeeplinkingData.fulfilled, (state, { payload }) => {
        state.pieStatusbyContractList = payload.data;
        state.chartDataListPagination = {
          ...state.chartDataListPagination,
          total: payload.total,
        };
        state.is_pieStatusbyContractLoading = false;
      })
      .addCase(getPieChartDeeplinkingData.rejected, (state, { payload }) => {
        state.is_pieStatusbyContractLoading = false;
      })
      .addCase(contractTypeVsContractCount.pending, (state) => {
        state.contractTypeVsContractCountLoading = true;
      })
      .addCase(contractTypeVsContractCount.fulfilled, (state, { payload }) => {
        state.contractTypeVsContractCountList = payload.data;

        state.contractTypeVsContractCountLoading = false;
      })
      .addCase(contractTypeVsContractCount.rejected, (state, { payload }) => {
        state.contractTypeVsContractCountLoading = false;
      })

      .addCase(dashboardModuleWisedCount.pending, (state) => {
        state.dashboardModuleWisedCountLoading = true;
      })
      .addCase(dashboardModuleWisedCount.fulfilled, (state, { payload }) => {
        state.dashboardModuleWisedCountList = payload.data;
        state.dashboardModuleWisedCountLoading = false;
      })
      .addCase(dashboardModuleWisedCount.rejected, (state, { payload }) => {
        state.dashboardModuleWisedCountLoading = false;
      })

      .addCase(contractTypeBasedCount.pending, (state) => {
        state.contractTypeBasedCountLoading = true;
      })
      .addCase(contractTypeBasedCount.fulfilled, (state, { payload }) => {
        state.contractTypeBasedCountList = payload.data;
        state.contractTypeBasedCountLoading = false;
      })
      .addCase(contractTypeBasedCount.rejected, (state, { payload }) => {
        state.contractTypeBasedCountLoading = false;
      })
      .addCase(getcontractByStatusCount.pending, (state) => {
        state.contractByStatusCountLoading = true;
      })
      .addCase(getcontractByStatusCount.fulfilled, (state, { payload }) => {
        state.contractByStatusCountList = payload.data;
        state.contractByStatusCountLoading = false;
      })
      .addCase(getcontractByStatusCount.rejected, (state, { payload }) => {
        state.contractByStatusCountLoading = false;
      })
      .addCase(getHistoricalContractStatusBaseCount.pending, (state) => {
        state.HistoricalContractStatusBaseCountLoading = true;
      })
      .addCase(
        getHistoricalContractStatusBaseCount.fulfilled,
        (state, { payload }) => {
          state.HistoricalContractStatusBaseCountList = payload.data;
          state.HistoricalContractStatusBaseCountLoading = false;
        }
      )
      .addCase(
        getHistoricalContractStatusBaseCount.rejected,
        (state, { payload }) => {
          state.HistoricalContractStatusBaseCountLoading = false;
        }
      )
      .addCase(getStaffwiseCount.pending, (state) => {
        state.isStaffWiseCountLoading = true;
      })
      .addCase(getStaffwiseCount.fulfilled, (state, { payload }) => {
        state.staffWiseCountList = payload.data;
        state.isStaffWiseCountLoading = false;
      })
      .addCase(getStaffwiseCount.rejected, (state, { payload }) => {
        state.isStaffWiseCountLoading = false;
      })
      .addCase(getContractCount.pending, (state) => {
        state.isStaffWiseCountLoading = true;
      })
      .addCase(getContractCount.fulfilled, (state, { payload }) => {
        state.contractCountList = payload.data;
        state.isStaffWiseCountLoading = false;
      })
      .addCase(getContractCount.rejected, (state, { payload }) => {
        state.isStaffWiseCountLoading = false;
      })
      .addCase(getContractsByStatusAndPeriod.pending, (state) => {
        state.isStaffWiseCountLoading = true;
      })
      .addCase(getContractsByStatusAndPeriod.fulfilled, (state, { payload }) => {
        state.ContractsByStatusAndPeriodList = payload.data;
        state.isStaffWiseCountLoading = false;
      })
      .addCase(getContractsByStatusAndPeriod.rejected, (state, { payload }) => {
        state.isStaffWiseCountLoading = false;
      });
  },
});

export const {
  setStatusFilterModal,
  selectedChartData,
  handleChartClickModal,
  onSearchList,
  onPageChangesFilter,
  resetvendorChartdata,
} = dashboadrSlice.actions;

export default dashboadrSlice.reducer;
