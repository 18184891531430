export const DASHBOARD_DEPT_ID = 1;
export const USER_DEPT_ID = 2;
export const TEMPLATE_DEPT_ID = 3;
export const CONTENT_DEPT_ID = 4;
export const WORKFLOW_DEPT_ID = 5;
export const STAFF_DEPT_ID = 6;
export const COMPANY_DEPT_ID = 7;
export const VENDOR_DEPT_ID = 8;
export const AGGREEMENT_MGMNT_DEPT_ID = 9;
export const TASK_MGMNT_DEPT_ID = 10;
export const TASK_MGMNT_VENDOR_DEPT_ID = 11;
export const MY_CONTRACT_DEPT_ID = 12;
export const KYC_DEPT_ID = 13;
export const MY_TASK = 14;
export const OTHER_DEPT_ID = 15;
export const OTHER_VENDOR_DEPT_ID = 16;
export const HISTORICAL_DEPT_ID = 17;
export const LICENCE_DEPT_ID = 18;
export const OBLIGATION = 19;
export const MYOBLIGATION_DEPT_ID = 20;
export const PLANS_DEPT_ID = 21;
export const NOTIFICATIONS_DEPT_ID = 22;
export const EXPRESS_DEPT_ID = 23;
