import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const headers2 = {
  "Content-type": "multipart/form-data",
};
const subUrllocal = "/api/v1/content";

class contentService {
  getContentAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/contents`, data, headers);
  createcontentAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  updatecontentAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  deletecontentAPI = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  getbackendvariablesAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/getBackendVars`, data, headers);
  peviewDataGetApi = (data) =>
    RestfulProvider.downloadMethod(
      `/api/v1/task/contract-preview`,
      data,
      headers
    );
  peviewfromcontentDataGetApi = (data) =>
    RestfulProvider.downloadMethod(`/api/v1/content/preview`, data, headers);

  //Relation Api
  getRelationDataListApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/getBackendVars`, data, headers);
  createRelationCheklistAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/getBackendVars`, data, headers);
  contentUploadDocAPi = (data) =>
    RestfulProvider.formDatapost(`/api/v1/task/upload-doc`, data, headers2);
  contentUploadhtmltoDocAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/htmltodoc`, data, headers);
}
// eslint-disable-next-line
export default new contentService();
