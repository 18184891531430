import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoading: true,
  error: null,
  isVisible: false,
  type: "",
  isDrawerVisible: false,
  isModalVisible: false,
  DrawerType: "",
  isDrawerRecord: {},
  isCreateInvoiceModalVisible: false,
  isViewInvoiceDrawerVisible: false,
  InvoiceRecords: {},
  isShareLinkModalVisible: false,
  expressWorkflowData: [
    {
      id: 1,
      name: "Car rental workflow",
      description: "description",
      status: "active",
      workflow: "Workflow 1",
    },
    {
      id: 2,
      name: "Urea fertiliser workflow",
      description: "description",
      status: "active",
      workflow: "Workflow 2",
    },
  ],
  expressInvoiceData: [
    {
      id: 1,
      name: "Express Contract 1",
      description: "Express Invoice 1 description",
      status: "Active",
    },
    {
      id: 2,
      name: "Express Contract 2",
      description: "Express Invoice 2 description",
      status: "Expired",
    },
  ],
  Carlist: [
    {
      id: 1,
      name: "Economy",
      description: "Affordable and fuel-efficient, perfect for city drives.",
      features: ["4 seats", "Automatic", "AC", "Compact size"],
      price_per_day: 35,
      image_src: "https://example.com/images/economy.jpg",
    },
    {
      id: "2",
      name: "Compact",
      description: "Easy to park with a bit more room than economy cars.",
      features: ["5 seats", "Automatic", "AC", "Ample luggage space"],
      price_per_day: 45,
      image_src: "https://example.com/images/compact.jpg",
    },
    {
      id: "3",
      name: "Midsize",
      description: "Comfortable and spacious, ideal for longer drives.",
      features: ["5 seats", "Automatic", "AC", "Bluetooth", "Cruise control"],
      price_per_day: 55,
      image_src: "https://example.com/images/midsize.jpg",
    },
    {
      id: "4",
      name: "SUV",
      description: "Spacious and powerful, suitable for families or groups.",
      features: ["7 seats", "Automatic", "AC", "4WD", "Large cargo space"],
      price_per_day: 75,
      image_src: "https://example.com/images/suv.jpg",
    },
    {
      id: "5",
      name: "Luxury",
      description: "Premium experience with luxury features.",
      features: [
        "5 seats",
        "Automatic",
        "AC",
        "Leather seats",
        "Sunroof",
        "Premium sound system",
      ],
      price_per_day: 120,
      image_src: "https://example.com/images/luxury.jpg",
    },
    {
      id: "6",
      name: "Convertible",
      description: "Enjoy the open road with a stylish convertible.",
      features: ["4 seats", "Automatic", "AC", "Convertible top", "Bluetooth"],
      price_per_day: 100,
      image_src: "https://example.com/images/convertible.jpg",
    },
    {
      id: "7",
      name: "Van",
      description: "Perfect for group travel or transporting large items.",
      features: ["8 seats", "Automatic", "AC", "Large cargo capacity"],
      price_per_day: 90,
      image_src: "https://example.com/images/van.jpg",
    },
  ],
  fertiliserList: [
    {
      id: 101,
      name: "Standard Urea",
      description: "High-quality nitrogen fertilizer for general use.",
      nitrogen_content: "46%",
      packaging_size: "50 kg",
      price_per_bag: 20,
      image_src: "https://example.com/images/standard-urea.jpg",
    },
    {
      id: 102,
      name: "Granular Urea",
      description:
        "Granular urea for easy application, ideal for broad-acre farming.",
      nitrogen_content: "46%",
      packaging_size: "50 kg",
      price_per_bag: 22,
      image_src: "https://example.com/images/granular-urea.jpg",
    },
    {
      id: 103,
      name: "Coated Urea",
      description:
        "Slow-release coated urea for sustained nitrogen availability.",
      nitrogen_content: "44%",
      packaging_size: "50 kg",
      price_per_bag: 25,
      image_src: "https://example.com/images/coated-urea.jpg",
    },
    {
      id: 104,
      name: "Prilled Urea",
      description:
        "Small, round prills for more uniform distribution in the soil.",
      nitrogen_content: "46%",
      packaging_size: "50 kg",
      price_per_bag: 21,
      image_src: "https://example.com/images/prilled-urea.jpg",
    },
    {
      id: 105,
      name: "Liquid Urea",
      description: "Liquid urea for foliar feeding and fertigation systems.",
      nitrogen_content: "30%",
      packaging_size: "20 liters",
      price_per_unit: 15,
      image_src: "https://example.com/images/liquid-urea.jpg",
    },
    {
      id: 106,
      name: "Urea-Ammonium Nitrate (UAN)",
      description: "A mixture of urea and ammonium nitrate in liquid form.",
      nitrogen_content: "32%",
      packaging_size: "1000 liters",
      price_per_unit: 500,
      image_src: "https://example.com/images/uan.jpg",
    },
  ],
};
const ExpressWorkflowSlice = createSlice({
  name: "ExpressWorkflow",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
    },
    setselectedItems: (state, { payload }) => {
      state.CurrentselectedItems = payload.item;
    },
    setDrawerVisible: (state, { payload }) => {
      state.isDrawerVisible = payload.visible;
      state.isDrawerRecord = payload.record || {};
    },
    CreateexpressWorkflow: (state, { payload }) => {
      state.expressWorkflowData = [
        ...state.expressWorkflowData,
        {
          ...payload,
          id: state.expressWorkflowData?.length + 1,
          name: `Express Workflow ${state.expressWorkflowData?.length + 1}`,
          description: `Express Workflow ${
            state.expressWorkflowData?.length + 1
          } description`,
          workflow: `Workflow ${state.expressWorkflowData?.length + 1} `,
          status: "active",
        },
      ];
      state.isVisible = false;
    },
    CreateexpressInvoice: (state, { payload }) => {
      state.expressInvoiceData = [
        ...state.expressInvoiceData,
        {
          ...payload,
          id: state.expressInvoiceData?.length + 1,
          name: `Express Invoice ${state.expressInvoiceData?.length + 1}`,
        },
      ];
      state.isCreateInvoiceModalVisible = false;
    },
    setCreateInvoiceModalVisible: (state, { payload }) => {
      state.isCreateInvoiceModalVisible = payload.visible;
    },
    setViewInvoiceDrawerVisible: (state, { payload }) => {
      state.isViewInvoiceDrawerVisible = payload.visible;
      state.InvoiceRecords = payload?.record;
    },
    setShareLinkModalVisible: (state, { payload }) => {
      state.isShareLinkModalVisible = payload.visible;
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(getContent.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(getContent.fulfilled, (state, { payload }) => {
    //     state.contentTableData = payload.data;
    //     state.isLoading = false;
    //     state.totalCount = payload.totalCount;
    //     state.contentPagination = {
    //       ...state.contentPagination,
    //       total: payload.total,
    //     };
    //   })
    //   .addCase(getContent.rejected, (state, { payload }) => {
    //     state.isLoading = false;
    //   })
  },
});
export const {
  setModalVisible,
  setDrawerVisible,
  CreateexpressInvoice,
  CreateexpressWorkflow,
  setCreateInvoiceModalVisible,
  setViewInvoiceDrawerVisible,
  setShareLinkModalVisible,
} = ExpressWorkflowSlice.actions;

export default ExpressWorkflowSlice.reducer;
