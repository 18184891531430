import { configureStore, combineReducers, compose } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import userSlice from "../modules/users/user.slice";
import RouteSlice from "../globals/store/route.slice";
import globalSlice from "./store/Globals.slice";
import layoutSlice from "../globals/layout/layout.slice";
import LocalSlice from "../globals/store/local.slice";
import TemplateSlice from "../modules/Template/Template.slice";
import StaffSlice from "../modules/Staff/Staff.slice";
import contentSlice from "../modules/Content/content.slice";
import WorkflowSlice from "../modules/Workflow/Workflow.slice";
import companySlice from "../modules/Company/company.slice";
import templateSlice from "../modules/TemplateServices/templateservice.Slice";
import WorkflowserviceSlice from "../modules/workflowServices/Workflowservice.Slice";
import contentserviceSlice from "../modules/ContentServices/contentservices.Slice";
import VendorSlice from "../modules/Vendor/Vendor.Slice";
import profileSlice from "../modules/Profile/Profile.Slice";
import TaskmanagementSlice from "../modules/Taskmanagement/Taskmanagement.Slice";
import TaskmanagementVendorSlice from "../modules/TaskmanagementVendor/TaskmanagementVendor.Slice";
import MyContractSlice from "../modules/myContracts/myContract.Slice";
import dashboardSlice from "../modules/dashboard/dashboard.Slice";
import MyTaskSlice from "../modules/MyTask/MyTask.Slice";
import OtherservicesSlice from "../modules/OtherServices/Otherservices.Slice";
import OtherservicescompanySlice from "../modules/OtherServicesCompany/Otherservicescompany.Slice";
import HistoricalServiceSlice from "../modules/historicalFlowSettings/historicalservice.Slice";
import HistoricalFlowSlice from "../modules/historicalFlow/historicalFlow.slice";
import LicencesSlice from "../modules/Licensing/Licences.Slice";
import ObligationSlice from "../modules/Obligation/Obligation.slice";
import MyObligationSlice from "../modules/MyObligation/MyObligation.Slice";
import ObligationserviceSlice from "../modules/Obligationservices/Obligationservices.Slice"
import EmailGuestloginSlice from "../modules/EmailGuestlogin/EmailGuestlogin.slice";
import VerifySlice from "../modules/VerifyContract/Verify.Slice";
import FreeTrialSlice from "../modules/FreeTrial/FreeTrial.slice";
import NotificationSlice from "../modules/Notification/Notification.Slice";
import ExpressWorkflowSlice from "../modules/ExpressWorkflow/Express.slice";
const rootReducer = combineReducers({
  user: userSlice,
  layout_store: layoutSlice,
  global: globalSlice,
  route: RouteSlice,
  local: LocalSlice,
  template: TemplateSlice,
  staff: StaffSlice,
  content: contentSlice,
  workflow: WorkflowSlice,
  company: companySlice,
  templateservice: templateSlice,
  workflowservice: WorkflowserviceSlice,
  contentservice: contentserviceSlice,
  vendor: VendorSlice,
  profile: profileSlice,
  taskmanagement: TaskmanagementSlice,
  taskmanagementVendor: TaskmanagementVendorSlice,
  myContract: MyContractSlice,
  dashboard: dashboardSlice,
  my_task: MyTaskSlice,
  otherservice: OtherservicesSlice,
  historical_services: HistoricalServiceSlice,
  Otherservicescompany: OtherservicescompanySlice,
  flowSlice: HistoricalFlowSlice,
  Licences:LicencesSlice,
  Obligation:ObligationSlice,
  MyObligation:MyObligationSlice,
  Obligationservices:ObligationserviceSlice,
  EmailGuestlogin:EmailGuestloginSlice,
  verify:VerifySlice,
  FreeTrial:FreeTrialSlice,
  Notification:NotificationSlice,
  ExpressWorkflow:ExpressWorkflowSlice
});

const store = configureStore(
  {
    reducer: rootReducer,
    devTools: true,
    middleware: [thunk],
  },
  (window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()) ||
    compose
);

export default store;

// export default configureStore({
//   reducer: {
//     user: userSlice
//   }
// });

//https://github.com/rt2zz/redux-persist/issues/613
